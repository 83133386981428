@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');


.transition-bg {
    transition: background-color 0.2s ease;
}

@keyframes marquee {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-50%); }
}

@keyframes marquee-reverse {
    0% { transform: translateX(-50%); }
    100% { transform: translateX(0%); }
}

.animate-marquee, .animate-marquee-reverse {
    display: block;
    animation: 40s linear infinite;
}

.animate-marquee {
    animation-name: marquee;
}

.animate-marquee-reverse {
    animation-name: marquee-reverse;
}
